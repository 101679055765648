<template>
  <section id="survey-filter">
    <label for="bot-filter">
      {{
        multipleSelect
          ? lang.metrics.filters.surveys.label[languageSelected]
          : lang.metrics.filters.survey.label[languageSelected]
      }}
    </label>

    <multiselect
      :key="`multiselect-${key.getTime()}`"
      v-model="value"
      tag-placeholder=""
      :placeholder="lang.metrics.filters.surveys.placeholder[languageSelected]"
      label="name"
      track-by="_id"
      :options="surveys"
      :multiple="multipleSelect"
      :taggable="multipleSelect"
      :show-labels="false"
      :loading="loading"
      @input="change"
    />

    <vs-button
      color="primary"
      type="flat"
      size="small"
      @click="clear"
      class="reset-button mt-1"
    >
      {{ lang.metrics.filters.range.clear[languageSelected] }}
    </vs-button>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import services from '../../../survey-maker/services'

export default {
  name: 'SurveyFilter',
  props: {
    multipleSelect: {
      type: Boolean,
      default: true
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    startAll: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  data() {
    return {
      key: new Date(),
      bots: [],
      value: [],
      surveys: [],
      loading: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    async getSurveys() {
      this.loading = true
      try {
        const result = await services.getSurveys()
        this.surveys = result.data.map(element => ({
          _id: element._id,
          service: element.service._id,
          name: element.service.name,
          _createdAt: element._createdAt
        }))
      } catch (error) {
        //TODO: create message
        this.$vs.notify({
          title: 'Oops!',
          text: this.lang.surveyCharts.create.messages.error.surveys[
            this.languageSelected
          ],
          color: 'danger'
        })
      } finally {
        this.loading = false
      }
    },
    change() {
      let selectedValues = []
      let value
      if (this.value) {
        if (this.multipleSelect) {
          value = this.value.length ? this.value : this.options
          selectedValues = value.map(survey => survey.service)
        } else {
          value = this.value
          selectedValues = [value.service]
        }
      }

      this.$emit('change', {
        type: 'survey',
        value: selectedValues
      })
    },
    clear() {
      this.value = []
      this.change()
    }
  },
  mounted() {
    this.getSurveys()
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#survey-filter {
  display: flex;
  flex-direction: column;

  label {
    display: block;
  }
  .vs-input--input {
    min-height: 43px;
  }

  .reset-button {
    align-self: flex-end;
  }
}
</style>
